import "./test.css";

import React, { useEffect } from "react";
import { Card, Flex, Segmented } from "../components/UI";
import SkeletonCard from "../components/UI/Loaders/SkeletonCard/SkeletonCard";
import useCustomContext from "../hooks/useCustomContext/useCustomContext";

const TestComponent = () => {
  const { theme, setTheme } = useCustomContext();

  useEffect(() => {
    console.log(theme);
  }, [theme]);
  return (
    <>
      <div id="test-area">Move mouse over this area.</div>
      <div id="mycursor">Move mouse over this area2</div>
    </>
  );
};

export default TestComponent;
