import { Dispatch, SetStateAction, useMemo } from "react";
import "./Accessability.css";
import { Drawer } from "../UI";
import { AccessibilityIcon } from "../UI/Icons";
import AccessabilityContent from "./AccessabilityContent/AccessabilityContent";
import useLocalStorage, { localStorageKeys } from "../../hooks/useLocalStorage";
import AccessabilityFooter from "./AccessabilityContent/AcceabilityFooter/AccessabilityFooter";
import { Language } from "../../types/types";

interface IProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  language: Language;
}

const Accessability = ({ language, isOpen, setIsOpen }: IProps) => {
  const [isVisible, setIsVisible] = useLocalStorage(localStorageKeys.accessabilityView, true, "sessionStorage");

  const dict = useMemo(() => {
    return {
      hebrew: "נגישות",
      english: "Accessibility",
      russian: "Доступность",
      arabic: "إمكانية الوصول",
    };
  }, []);

  if (!isVisible) return null;

  return (
    <>
      {!isOpen && (
        <div className="accessability-float-btn ">
          <AccessibilityIcon clickable onClick={() => setIsOpen(true)} />
        </div>
      )}
      <Drawer
        footer={<AccessabilityFooter handleRemove={() => setIsVisible(false)} />}
        title={dict[language] + " (CTRL + U)"}
        width="400px"
        placement="right"
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
      >
        {isOpen ? <AccessabilityContent /> : null}
      </Drawer>
    </>
  );
};

export default Accessability;
