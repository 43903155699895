import { ReactNode } from "react";
import "./InfoBox.css";
import { Language } from "../../../types/types";

export type InfoBoxType = "info" | "gray" | "warning" | "success" | "error";

interface IinfoBoxProps {
  content: string | ReactNode;
  type: InfoBoxType;
  language: Language;
  fontColor?: string;
  cssClass?: string;
}

const InfoBox = ({ content, type = "info", language = "hebrew", fontColor, cssClass }: IinfoBoxProps) => {
  return (
    <div className={`info-box info-box-${type} info-box-${language} ${cssClass ?? ""}`} style={{ color: fontColor }}>
      <p>{content}</p>
    </div>
  );
};

export default InfoBox;
