import CategoriesMap from "../assets/Data/categories";
import imageNoImage from "../assets/images/Image_not_available.png";
import { Language, User } from "../types/types";

export const handleImageError = async (event: any, size?: "small" | "medium") => {
  event.target.src = imageNoImage;

  if (size && size === "small") {
    event.target.width = 75;
    event.target.height = 75;
  } else if (size && size === "medium") {
    event.target.height = 150;
    event.target.width = 150;
  }
};

export const directionByLanguage = (language: Language) => {
  if (["arabic", "hebrew"].includes(language)) return "rtl";
  return "ltr";
};

function isWordWithLettersAndNumbers(word: string) {
  return /\d/.test(word) && /[א-ת]/.test(word);
}

function addSpacing(word: string) {
  return word.replace(/([א-ת])(\d)/g, "$1 $2").replace(/(\d)([א-ת])/g, "$1 $2");
}

export function addSpacingBetweenNumbersAndLetters(text: string) {
  if (!text) return "";
  const strArr = text.split(" ");
  for (let i = 0; i < strArr.length; i++) {
    if (isWordWithLettersAndNumbers(strArr[i])) {
      strArr[i] = addSpacing(strArr[i]);
    }
  }
  return strArr.join(" ");
}

export const parseJwt = (token: string) => {
  try {
    const payload = token.split(".")[1];
    const decodedPayload = JSON.parse(
      decodeURIComponent(
        atob(payload)
          .split("")
          .map((c) => "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2))
          .join("")
      )
    );

    return decodedPayload;
  } catch (e) {
    return null;
  }
};

export const checkTokenExpIsValid = (decodedJwt: { [key: string]: any } | null) => {
  const exp = decodedJwt?.exp;
  if (exp * 1000 < Date.now()) {
    // token expired
    return false;
  }
  return true;
};

export const snakeToCamel = (str: string) => {
  return str.replace(/_([a-z])/g, function (match, letter) {
    return letter.toUpperCase();
  });
};

// register , login
export const parseCookies = (cookieName: string) => {
  const cookies = document.cookie.split("; ");

  for (const cookie of cookies) {
    const [name, value] = cookie.split("=");
    if (name === cookieName) {
      return value;
    }
  }
};

//register
export function isValidIsraelPhoneNumber(phoneNumber: string) {
  const israelPhoneNumberPattern = /^05\d{8}$/;
  return israelPhoneNumberPattern.test(phoneNumber);
}

export const updateUserContextFromSnakeToCamelCase = (initialSUserStateStorage: any) => {
  const initialSUserState: User = {
    isUser: false,
    city: null,
    email: "guest",
    phoneNumber: undefined,
    fullName: "Guest",
    language: "" as Language,
  };

  for (const key in initialSUserStateStorage) {
    const camelKey = snakeToCamel(key) as keyof User;
    if (Object.prototype.hasOwnProperty.call(initialSUserState, camelKey)) {
      (initialSUserState as any)[camelKey] = initialSUserStateStorage[key];
    }
  }

  return initialSUserState;
};

export const resolveDirection = (language: Language, trueFalse: ["right" | "left", "right" | "left"]) => {
  return ["hebrew", "arabic"].includes(language) ? trueFalse[0] : trueFalse[1];
};

export const resolveDirClass = (language: Language) => {
  return ["hebrew", "arabic"].includes(language) ? "rtl" : "ltr";
};

export const getTranslatedCategory = (language: Language) => {
  const translatedCategories: any = {};
  for (const category of Object.values(CategoriesMap)) {
    for (const sub of category.subCategories) {
      translatedCategories[sub.key] = sub[language]; // You can change 'hebrew' to the desired language
    }
  }
  return translatedCategories;
};

export const sortStr = (strA: string, strB: string) => {
  if (strA < strB) {
    return -1;
  } else if (strA > strB) {
    return 1;
  } else {
    return 0;
  }
};
