import { Dispatch, FC, SetStateAction, useState } from "react";
import "./CartHeader.css";
import { SettingsIcon } from "../../UI/Icons";
import CloseBtn from "../../UI/CloseBtn/CloseBtn";
import CartModel from "../../../models/Cart/CartModel";
import { Drawer, Typography, PopConfirm } from "../../UI";
import { cartSortingMethods } from "../CartContent/CartContent";
import RadioGroup, { RadioGroupOption } from "../../UI/RadioGroup/RadioGroup";
import useCustomContext from "../../../hooks/useCustomContext/useCustomContext";
import { dictStores } from "../../../dictionary/dictStores";

type Props = {
  setSortingMethod?: Dispatch<SetStateAction<cartSortingMethods>>;
};

const sortOptions: RadioGroupOption[] = [
  { label: "סדר הוספה לעגלה", value: "order" },
  { label: "אותיות", value: "abc" },
  { label: "לפי קטגוריות", value: "categories" },
];

const dict = {
  myCart: {
    hebrew: "העגלה שלי לפי ",
    english: "My Cart According ",
    arabic: "",
    russian: "",
  },
};

const CartHeader: FC<Props> = ({ setSortingMethod }) => {
  const {
    cart,
    setCart,
    setIsCartOpen,
    onlineStores,
    user: { language },
  } = useCustomContext();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const handleCleanCart = () => {
    setCart(CartModel.cleanCart());
  };

  if (!setSortingMethod || !Object.keys(cart).length) {
    return (
      <CloseBtn
        showDivider={false}
        closeIconColor="var(--white)"
        title={
          <div className="cart-header">
            <span>{dict.myCart[language]}</span>
          </div>
        }
        onClose={() => setIsCartOpen(false)}
      />
    );
  }

  const HandleSortingMethod = (value: string) => {
    switch (value) {
      case "order":
        setSortingMethod("order");
        break;
      case "abc":
        setSortingMethod("abc");
        break;
      case "categories":
        setSortingMethod("categories");
        break;

      default:
        break;
    }
  };

  return (
    <>
      <CloseBtn
        showDivider={false}
        closeIconColor="var(--white)"
        title={
          <div className="cart-header">
            <div onClick={() => setIsDrawerOpen((p) => !p)}>
              <SettingsIcon size="18px" clickable />
            </div>
            <div>
              <span>{dict.myCart[language]}</span>
              <span>{dictStores[onlineStores.favoriteStore][language]}</span>
            </div>
          </div>
        }
        onClose={() => setIsCartOpen(false)}
      />
      <Drawer
        onClose={() => setIsDrawerOpen(false)}
        isOpen={isDrawerOpen}
        placement="top"
        getContainer
        closable
        headerStyle={{ backgroundColor: "var(--primary-color)", color: "var(--white)" }}
        title="אפשרויות נוספות"
      >
        <div style={{ padding: "5px 15px" }}>
          <Typography.Title level={6}> מיון פריטים בעגלה לפי:</Typography.Title>
          <RadioGroup
            style={{ display: "flex", flexDirection: "column" }}
            options={sortOptions}
            name="sortingCart"
            onChange={HandleSortingMethod}
          />
        </div>

        <div style={{ margin: "10px" }}>
          <PopConfirm
            description="האם אתם בטוחים שברצונכם לרוקן את העגלה?"
            onConfirm={() => handleCleanCart()}
            onCancel={() => console.log("x")}
            title="ריקון עגלה"
            closable={false}
          >
            ריקון עגלה
          </PopConfirm>
        </div>
      </Drawer>
    </>
  );
};

export default CartHeader;
