import { Dictionary } from "../../types/types";
import { Card, Flex, InfoBox, Modal } from "../UI";
import useCustomContext from "../../hooks/useCustomContext/useCustomContext";
import serverAPI from "../../api/server";
import useLocalStorage, { localStorageKeys } from "../../hooks/useLocalStorage";
import AlertMessage from "../UI/AlertMessage/AlertMessage";

const dict: Dictionary = {
  title: {
    hebrew: "",
    english: "Language Support",
    arabic: "",
    russian: "",
  },
  contentA: {
    hebrew: "",
    arabic: "",
    english:
      "The following feature is currently available in Hebrew only. We’re working hard to make it accessible to all our users.",
    russian: "",
  },
  contentB: {
    arabic: "",
    english:
      "If you’d like to see this feature in your language sooner, please provide us a feedback—it will help us prioritize it.",
    hebrew: "",
    russian: "",
  },
  sendFeedBackBtn: {
    hebrew: "",
    arabic: "",
    russian: "",
    english: "Send Feedback",
  },
};

const HebrewOnlyInfo = ({ source, type }: { source: string; type?: "infoBox" | "modal" | "alert" | "message" }) => {
  const {
    user: { isUser, fullName, email, language, phoneNumber },
  } = useCustomContext();
  const [langSupportStorage, setLangSupportStorage] = useLocalStorage(localStorageKeys.LanguageSupport, {});

  if (language === "hebrew" || langSupportStorage[source]) return null;

  const handleClose = () => {
    setLangSupportStorage({ ...langSupportStorage, [source]: true });
  };

  const onFeedBack = async () => {
    const subject = `Language Support missing - ${source}`;
    const message = `${language} is missing in ${source} component\nProvided at ${new Date().toDateString()}`;
    await serverAPI("users/contact", { fullName, subject, phone: phoneNumber, email, message });
    handleClose();
  };

  const message = <InfoBox type="info" content={dict.contentA[language]} language={language} />;
  const message2 = <InfoBox type="gray" content={dict.contentB[language]} language={language} />;

  if (type === "message")
    return <AlertMessage delay={6} message={dict.contentA[language]} position="bottom" type="warning" />;
  else if (type === "infoBox")
    return (
      <Card>
        <Flex flexDirection="column" gap={5} align="center" justify="center">
          {message}
          {isUser && message2}
          {isUser && (
            <div
              className="button btn-dashed"
              onClick={(e) => {
                e.stopPropagation();
                onFeedBack();
              }}
            >
              {dict.sendFeedBackBtn[language]}
            </div>
          )}
        </Flex>
      </Card>
    );
  else if (type === "modal")
    return (
      <Modal
        zIndex={1001}
        title={dict.title[language]}
        withFooter={isUser}
        okText={dict.sendFeedBackBtn[language]}
        cancelText="close"
        maskClosable
        onOk={onFeedBack}
        onCancel={handleClose}
        width={400}
        visible={!langSupportStorage[source]}
      >
        <form>
          {message}
          {isUser && message2}
        </form>
      </Modal>
    );

  alert(dict.contentA.english);
  return null;
};

export default HebrewOnlyInfo;
