import { useState, useEffect, Dispatch, SetStateAction } from "react";
import { CartStorage, OnlineStoresType } from "../types/types";
import { ThemeType } from "../components/Accessability/AccessabilityContent/AccesabilityContentOptions/AccessabilityOption";

export enum localStorageKeys {
  cart = "cart",
  userOnlineStores = "userOnlineStores",
  city = "city",
  accessabilityView = "accessability",
  AccessabilityOptions = "AccessabilityOptions",
  LanguageSupport = "lSupport",
}

type InitialValueTypes = {
  [localStorageKeys.cart]: CartStorage[];
  [localStorageKeys.userOnlineStores]: OnlineStoresType;
  [localStorageKeys.city]: string;
  [localStorageKeys.LanguageSupport]: Record<string, boolean>;
  [localStorageKeys.accessabilityView]: boolean;
  [localStorageKeys.AccessabilityOptions]: ThemeType;
};

type StorageType = "localStorage" | "sessionStorage";

const useLocalStorage = <K extends keyof InitialValueTypes>(
  key: K,
  initialValue: InitialValueTypes[K],
  storageType: StorageType = "localStorage"
): [InitialValueTypes[K], Dispatch<SetStateAction<InitialValueTypes[K]>>] => {
  const storage = storageType === "localStorage" ? localStorage : sessionStorage;

  const [value, setValue] = useState(() => {
    const storedValue = storage.getItem(key);

    return storedValue ? JSON.parse(storedValue) : initialValue;
  });

  useEffect(() => {
    storage.setItem(key, JSON.stringify(value));
  }, [key, value, storage]);

  return [value, setValue];
};

export default useLocalStorage;
