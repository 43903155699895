import { FC } from "react";
import { IconCustom } from "./types";

const AccessibilityIcon: FC<IconCustom> = ({ clickable, color = "var(--white)", size = "80px", onClick }) => {
  return (
    <svg
      width={size}
      // height={size}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 600 404.801"
      enableBackground="new 0 0 655.61 404.801"
      xmlSpace="preserve"
      onClick={onClick}
      className={clickable ? "clickable-icon" : ""}
    >
      {/* <rect width="100%" height="100%" fill={"red"} /> */}
      <path
        fill="var(--black)"
        d="M648.61,202.4c0,107.916-96.09,195.4-214.623,195.4H187.624
						C103.091,397.801,7,310.316,7,202.4l0,0C7,94.484,103.091,7,187.624,7h212.363C552.52,7,648.61,94.484,648.61,202.4L648.61,202.4z"
      ></path>
      <path
        fill={color}
        d="M177.979,283.354c11.381,21.244,33.839,35.699,59.637,35.703
						c37.355-0.006,67.631-30.279,67.635-67.635c0.002-22.354-10.872-42.229-27.592-54.512c-3.587-2.641-8.637-1.873-11.276,1.717
						c-2.639,3.588-1.87,8.637,1.717,11.275c12.763,9.404,21.019,24.465,21.019,41.52c-0.002,14.246-5.751,27.07-15.083,36.416
						c-9.346,9.336-22.17,15.082-36.419,15.086c-19.663,0.004-36.722-11.002-45.422-27.199v0.002c-2.104-3.926-6.994-5.402-10.921-3.297
						C177.349,274.535,175.873,279.426,177.979,283.354z M249.404,108.082c0-12.336-10.001-22.338-22.339-22.338
						c-12.337,0-22.338,10.002-22.338,22.338c0,12.338,10.001,22.34,22.338,22.34C239.403,130.422,249.404,120.42,249.404,108.082z
						 M142.754,278.146c-3.06,4.754-1.687,11.088,3.067,14.148c1.714,1.104,3.634,1.631,5.532,1.631c3.365,0,6.661-1.658,8.618-4.697
						l31.724-49.287h42.194c0.098,0,0.193-0.014,0.29-0.016c1.114,0.209,2.262,0.322,3.437,0.322c10.279,0,18.612-8.334,18.612-18.615
						V155.24c0-10.281-8.333-18.615-18.612-18.615c-6.84,0-12.813,3.689-16.05,9.186l-25.235,25.236l-29.416,0.002
						c-4.625,0-8.373,3.748-8.373,8.375c0,4.625,3.748,8.377,8.373,8.377H199.8c2.205,0,4.365-0.895,5.924-2.453l13.278-13.279v47.396
						h-32.891c-3.491,0-6.719,1.76-8.607,4.695L142.754,278.146z M129.093,167.498l17.998,38.471c0.665,1.422,2.084,2.324,3.653,2.324
						h50.881c2.228,0,4.032-1.805,4.032-4.031c0-2.229-1.805-4.035-4.032-4.035H153.31l-16.912-36.148
						c-0.941-2.016-3.343-2.885-5.36-1.941C129.021,163.078,128.149,165.48,129.093,167.498z"
      ></path>
    </svg>
  );
};

export default AccessibilityIcon;
